@use '../variables.scss' as *;

@mixin order($width, $height, $marginLeft, $marginTop, $fontSize) {
  width: rem($width);
  height: rem($height);
  margin-left: rem($marginLeft);
  margin-top: rem($marginTop);
  font-size: rem($fontSize);
}
app-order-history-table .reOrder-orderHistory {
  .reorder-button,
  .cancel__button {
    @include order(100px, 25px, 25px, 8px, 12px);
    @media (min-width: $breakpoint-tablet) {
      @include order(150px, 40px, 0px, 0px, 16px);
    }
  }
  .cancel__button {
    padding: 0;
  }
}
