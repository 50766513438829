@use '../../styles/variables.scss' as *;

@mixin fontStyles($family, $size, $style, $weight, $lineHeight) {
  font-family: $family;
  font-size: rem($size);
  font-style: $style;
  font-weight: $weight;
  line-height: rem($lineHeight);
}

.TabVCFAQGuidePosition {
  background: $lightSteelBlue;
  border-radius: rem(10px);
  height: rem(233px);
  min-width: rem(728px);
  padding: rem(30px) rem(30px) rem(48px) rem(30px);

  cx-paragraph:nth-child(1) > p {
    margin: 0;
    margin-bottom: rem(30px);

    h3 {
      @include fontStyles($openSans-regular, 26px, normal, 300, 27px);
      color: $alcon-true-blue;
      height: rem(27px);
      margin: 0;
      text-align: center;
    }
  }

  cx-link {
    border-radius: rem(20px);
    border: 1px solid $alcon-true-blue;
    box-sizing: border-box;
    height: rem(40px);
    min-width: rem(300px);
    padding: rem(12px) 0 rem(12px) rem(51px);

    cx-generic-link {
      a {
        @include fontStyles($openSans-regular, 16px, normal, 700, 16px);
        align-items: baseline;
        background: url(../../assets/Icons/download-icon.svg) no-repeat;
        background-position: top -1px right 15px;
        color: $alcon-true-blue;
        display: flex;
        height: rem(24px);
        text-transform: uppercase;
      }
    }
  }

  cx-paragraph:nth-child(2) > div {
    margin-bottom: rem(40px);

    a {
      @include fontStyles($openSans-regular, 16px, normal, 400, 18px);
      color: $dimBlack;
      margin-bottom: rem(40px);
      text-align: center;
      text-decoration: underline;
    }
  }
}
