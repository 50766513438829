@use '../variables.scss' as *;

app-header-link-nav > ul > li > a > icon > cx-media > img,
app-header-link-nav > ul > li:not(.new-order) > .nav-link > icon > cx-media > img {
  width: 32px;
  height: 32px;
  @media (min-width: $breakpoint-tablet) {
    width: auto;
    height: auto;
  }
}
