@use '../../styles/variables.scss' as *;

@mixin fontStyles($family, $size, $style, $weight, $lineHeight) {
  font-family: $family;
  font-size: rem($size);
  font-style: $style;
  font-weight: $weight;
  line-height: rem($lineHeight);
}

.TabVCFAQContactPosition {
  background: $primary-light-beige;
  border-radius: rem(10px);
  height: rem(233px);
  min-width: rem(728px);
  padding: rem(30px);

  cx-paragraph:nth-child(1) > p {
    margin: 0;
    margin-bottom: rem(30px);

    h3 {
      @include fontStyles($openSans-regular, 26px, normal, 300, 27px);
      color: $alcon-true-blue;
      height: rem(27px);
      margin: 0;
      text-align: center;
    }
  }

  cx-paragraph:nth-child(2) {
    margin-bottom: rem(30px);
    div {
      @include fontStyles($openSans-regular, 16px, normal, 300, 22px);
      color: $dimBlack;
      margin-bottom: 0;
      text-align: center;
    }
  }

  cx-link {
    background-color: $alcon-true-blue;
    border-radius: rem(1000px);
    height: rem(40px);
    min-width: rem(222px);
    padding: rem(12px) rem(59px);
    cx-generic-link {
      a {
        @include fontStyles($openSans-regular, 16px, normal, 700, 16px);
        align-items: baseline;
        color: $white;
        display: flex;
        letter-spacing: 0.02em;
        text-transform: uppercase;
      }
    }
  }
}
