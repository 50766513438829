@use './variables.scss' as *;
.faq-accordion {
  .accordion {
    .accordion-item {
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 0;
      word-wrap: break-word;
      background-color: $white;
      background-clip: border-box;
      border-radius: rem(4px);
      border: none;
    }

    .accordion-header {
      margin-bottom: 0;
      background: none;
      padding: 0px;
      h5 {
        font-size: 16px;
        font-family: 'OpenSans-Bold';
        color: $dimBlack;
      }
    }
    .accordion > .accordion-item .collapsed {
      background-color: $lightSteelBlue;
      border-bottom: 1px solid $white;
      margin-top: rem(2px);
    }
    .accordion > .accordion-item > .accordion-header {
      margin-bottom: 0px;
      border-bottom: 1px solid $lightGrey;
      background: none;
      padding: 0px;
    }
    .accordion-body {
      flex: 1 1 auto;
      min-height: 1px;
      padding: rem(12px) rem(20px);
      background-color: $lightSteelBlue;
      @media (max-width: $breakpoint-tablet-max) {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .acc-header {
      padding: rem(12px) rem(20px);
      border-bottom: 1px solid $lightGrey;
    }

    .acc-btn:focus {
      outline: 0;
      box-shadow: none;
    }
  }
}

.pdpContainer {
  .accordion {
    .card {
      border: none;
      margin-bottom: rem(14px);
    }
    .card-header {
      padding: 0;
      background-color: transparent;
      h5 {
        color: $dimBlack;
        font-family: $openSans-semiBold;
        font-size: rem(16px);
        font-weight: 600;
        height: rem(26px);
        line-height: rem(22px);
      }
    }
    .accordion > .card > .card-header {
      background: none;
      border-bottom: 1px solid $lightGrey;
      margin-bottom: 0;
      padding: 0;
    }
    .card-body {
      padding: 0;
      padding-top: rem(17px);
    }
    .acc-header {
      border-bottom: 1px solid $lightGrey;
      padding-bottom: rem(8px);
    }
    .acc-header.opened {
      border-bottom: 1px solid $white;
      margin-top: rem(2px);
    }
    .acc-btn:focus {
      outline: 0;
      box-shadow: none;
    }
  }
}
