@use '../../styles/variables.scss' as *;

app-stockorder-toric {
  app-dropdown-selector-v2 {
    .dropdown-header,
    .dropdown-options {
      min-height: 31px;
      padding: rem(9px) rem(10px);
      width: 80px;
    }
    .dropdown__icon {
      left: 59px;
      right: unset;
      top: 11px;
    }
    .dropdown-options__header,
    .dropdown-options__item {
      padding-left: rem(10px);
    }
  }
}
