@use '../variables.scss' as *;

@media (max-width: $breakpoint-tablet-max) {
  .cart-single-line-item {
    &__price-counter {
      grid-row: 1 / 2;
      display: grid !important;
      row-gap: rem(20px);

      app-alcon-item-counter {
        justify-self: end;
      }
    }

    &__item-wrap {
      display: grid !important;
      grid-template-rows: repeat(6, auto);
      grid-template-columns: repeat(7, 1fr);
      padding: rem(20px) 0 rem(12px) 0;

      @media (min-width: $breakpoint-tablet) {
        padding: rem(20px) 0;
      }

      app-cart-product-information {
        display: grid;
        grid-column: 1 / 5;
        grid-row: 1 / -1;
      }
    }

    &__actions {
      grid-row: 1 / -1;
      grid-column: 5 / -1;
      display: grid;
      grid-template-rows: repeat(2, 1fr);
      grid-row-gap: rem(10px);

      app-cart-actions {
        grid-row: 2 / -1;
        align-self: end;
      }
    }
  }

  app-cart,
  app-order-details-page {
    app-cart-product-information {
      .cart-product {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: min-content 1fr;
        overflow: auto;

        &__name {
          padding-top: 0 !important;
          padding-bottom: rem(25px) !important;
          font-family: 'OpenSans-SemiBold' !important;
          font-size: rem(16px) !important;
          grid-row: 1 / 2;
          grid-column: 1 / -1;
        }

        &__info {
          grid-row: 2 / -1;
          grid-column: 1 / -1;
          display: grid !important;
          grid-template-columns: min-content 1fr;
        }

        &__specs {
          display: grid !important;
        }
      }
    }

    app-alconcart {
      app-cart-single-line-item {
        .cart-single-line-item {
          @media (max-width: $breakpoint-tablet) {
            padding: 0 !important;
          }
        }

        app-alcon-item-counter {
          .counter__watp {
            @media (max-width: $breakpoint-tablet) {
              float: right;
            }
          }
        }
      }
    }
  }

  app-cart-product-information .cart-product__image-background {
    flex: 0 0 rem(101px);
  }
}
