@mixin wd($wd: 100%) {
  width: $wd;
}
.b2b-col-5 {
  @include wd(5%);
}
.b2b-col-6 {
  @include wd(6%);
}
.b2b-col-7 {
  @include wd(7%);
}
.b2b-col-8 {
  @include wd(8%);
}
.b2b-col-9 {
  @include wd(9%);
}
.b2b-col-10 {
  @include wd(10%);
}
.b2b-col-11 {
  @include wd(11%);
}
.b2b-col-13 {
  @include wd(13%);
}
.b2b-col-14 {
  @include wd(14%);
}
.b2b-col-15 {
  @include wd(15%);
}
.b2b-col-20 {
  @include wd(20%);
}
.b2b-col-21 {
  @include wd(21%);
}
.b2b-col-25 {
  @include wd(25%);
}
.b2b-col-26 {
  @include wd(26%);
}
.b2b-col-28 {
  @include wd(28%);
}
.b2b-col-32 {
  @include wd(32%);
}
.b2b-col-39 {
  @include wd(39%);
}
.b2b-col-40 {
  @include wd(40%);
}
.b2b-col-48 {
  @include wd(48%);
}
.b2b-col-58 {
  @include wd(58%);
}
.b2b-col-60 {
  @include wd(60%);
}
.b2b-col-61 {
  @include wd(61%);
}
.b2b-col-65 {
  @include wd(65%);
}
.b2b-col-100 {
  @include wd;
}
