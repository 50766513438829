@import 'variables.scss';

#okta-signin-username,
#okta-signin-password,
#account-recovery-username,
.o-form-input-name-passCode input,
.o-form-input-name-answer input {
  background-color: $white;
  border-radius: 1.56rem;
  border: 1px solid $lightGrey;
  color: $dimBlack;
  font-family: 'OpenSans-Regular';
  font-size: 1rem;
  height: 3.12rem;
  line-height: 1.18rem;
  text-align: left;
  width: 100%;
  padding: 0.375rem 0.75rem;
  margin-bottom: rem(10px);
}
.o-form-input-name-passCode input {
  width: 50% !important;
}
#okta-signin-username:focus,
#okta-signin-password:focus,
#account-recovery-username:focus,
.o-form-input-name-answer:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.okta-form-label {
  color: $navy-blue;
  font-family: 'OpenSans-Regular';
  font-size: 0.75rem;
  line-height: 1.375rem;
}

.okta-form-input-error {
  color: $bright-red;
  font-family: 'OpenSans-Regular';
  font-size: rem(12px);
  height: 1.0625rem;
  line-height: 14px;
  margin-top: 5px;
  text-align: right;
  margin-bottom: 0;
}

.okta-form-infobox-error {
  color: $bright-red;
  font-family: 'OpenSans-Regular';
  font-size: rem(12px);
}

.custom-checkbox {
  float: right;
  font-size: rem(12px);
  padding: 5px;

  label {
    padding: 5px;
  }
}

#okta-signin-submit,
.o-form-button-bar .button-primary {
  border-radius: 1.56rem;
  color: $white;
  font-family: 'OpenSans-Bold';
  font-size: 1rem;
  line-height: 1.18rem;
  text-align: center;
  width: 200px;
  background-color: $navy-blue;
  border-radius: rem(25px);
  height: 50px;
  text-align: center;
  border: none;
  margin: 5px;
}

.o-form-button-bar,
.auth-footer {
  text-align: right;
}

.js-help,
.o-form-fieldset-container,
.js-back,
.js-forgot-password,
.js-help-link {
  font-size: rem(12px);
  margin-right: rem(10px);
}

.js-help-links {
  font-size: rem(12px);
  list-style: none;
}

.auth-divider,
.social-auth-button,
.registration-container {
  display: none;
}

// [data-se='back-link']

.chzn-drop {
  margin: 48px 0 0;

  .chzn-search {
    display: none;
  }

  .chzn-results {
    margin: 0 -1px;
    padding: 0;
    list-style-type: none;
    background: #fafafa;
    border-radius: 0 0 10px 10px;
    border: 1px solid #ccc;
    overflow: hidden;

    li {
      padding: 10px 20px;
      font-size: 14px;
      cursor: pointer;

      &.result-selected {
        background-color: #f2f2f2;
      }
      &.highlighted {
        background-color: #deeef8;
      }
    }
  }
}

.chzn-container {
  background-color: $white;
  border-radius: 1.56rem 1.56rem 0 0;
  border: 1px solid $lightGrey;
  color: $dimBlack;
  font-family: 'OpenSans-Regular';
  font-size: 1rem;
  height: 3.12rem;
  line-height: 1.18rem;
  text-align: left;
  padding: 0;
  z-index: 1 !important;

  .chzn-single {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 18px);
    bottom: 0;
    line-height: 46px;
    padding: 0 0 0 18px;
    color: #232323;

    &:hover {
      text-decoration: none;
    }

    &:after {
      content: '';
      background-image: url('../assets/Icons/dropdown-expand.svg');
      position: absolute;
      width: 15px;
      height: 10px;
      background-repeat: no-repeat;
      top: 20px;
      right: -4px;
    }
  }
}

.registration {
  position: relative;

  .okta-form-title {
    color: $dimBlack;
    font-family: 'OpenSans-Light';
    font-size: rem(40px);
    line-height: 34px;
    text-align: center;
    margin-bottom: rem(46px);
  }

  .auth-footer {
    position: absolute;
    right: 230px;
    bottom: 18px;
    z-index: 1;
    font-size: 18px;
  }

  .o-form-control {
    display: block;
    position: relative;
  }

  .password-toggle .eyeicon {
    right: 20px;
    position: absolute;
    top: 40px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    text-transform: uppercase;
    color: #003593;
    font-weight: bold;

    &:before {
      position: absolute !important;
      top: 5px !important;
      left: 3px !important;
      content: '';
      display: inline-block !important;
      width: 23px !important;
      height: 20px !important;
      background-size: 25px;
    }

    &.button-hide {
      display: none;

      &:before {
        content: '';
        background: url('../assets/Icons/showeye.svg');
        background-size: 100%;
        border: 0px;
      }
    }

    &.button-show:before {
      content: '';
      background: url('../assets/Icons/hideeye.svg');
      background-size: 100%;
      border: 0px;
    }
  }

  .o-form-input-name-firstName:before,
  .o-form-input-name-lastName:before,
  .o-form-input-name-email:before,
  .o-form-input-name-password:before,
  .o-form-input-name-confirmPassword:before,
  .o-form-input-name-mobilePhone:before,
  .o-form-input-name-sapId:before,
  .o-form-input-name-postalCode:before {
    content: 'First name*';
    color: $true-blue;
    font-family: 'OpenSans-Regular';
    font-size: 0.75rem;
    line-height: 1.375rem;
    display: block;
    margin-bottom: 0.5rem;
  }
  .o-form-input-name-sapId {
    input {
      width: 50% !important;
    }
  }
  .o-form-input-name-sapId + p {
    width: 50% !important;
  }

  .o-form-input-name-lastName:before {
    content: 'Last name*';
  }

  .o-form-input-name-email:before {
    content: 'E-mail address for MyAlcon account*';
  }

  .o-form-input-name-password:before {
    content: 'Create your password*';
  }

  .o-form-input-name-confirmPassword:before {
    content: 'Confirm your password*';
  }

  .o-form-input-name-mobilePhone:before {
    content: 'Mobile phone';
  }

  .o-form-input-name-sapId:before {
    content: 'Alcon account number*';
  }

  .o-form-input-name-postalCode:before {
    content: 'Postal code*';
  }

  .chzn-container {
    width: 100% !important;
    position: relative !important;
    padding: 0.375rem 0.75rem;
    border-radius: 1.56rem;

    .chzn-drop {
      display: none !important;
    }
  }
  .required-fields-label {
    margin-top: -20px;
  }

  .o-form-fieldset-container {
    display: flex;
    margin-right: 0;
    flex-wrap: wrap;

    .subschema {
      padding: 10px 0 20px 4px;
      margin-top: -26px;
      color: $classicMatteGrey;

      .subschema-error {
        color: red;
      }

      &:before {
        content: 'Password requirements:';
        font-weight: bold;
        position: relative;
        top: -6px;
      }

      p {
        display: list-item;
        list-style-type: inherit;
        margin: 0 0 3px 20px;
      }
    }

    .o-form-fieldset {
      flex: 0 0 100%;
      margin-bottom: rem(28px);

      .o-form-input-name-consentData {
        br {
          display: none;
        }
        div {
          overflow: hidden;

          input {
            float: left;
            width: 22px;
            margin: 3px 0 0;
          }

          label {
            width: calc(100% - 22px);
            float: left;
            text-align: justify;

            a {
              text-decoration: underline;
            }
          }
        }
        & + .okta-form-input-error.o-form-input-error.o-form-explain {
          display: none !important;
        }
      }

      input:not(.o-form-input-name-consentData input) {
        background-color: $white;
        border-radius: 1.56rem;
        border: 1px solid $lightGrey;
        color: $dimBlack;
        font-family: 'OpenSans-Regular';
        font-size: 1rem;
        height: 3.12rem;
        line-height: 1.18rem;
        text-align: left;
        width: 100%;
        padding: 0.375rem 0.75rem;

        &:focus {
          outline: 0;
          box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
        }
      }

      .o-form-has-errors {
        input {
          border: 1px solid $bright-red;

          &:focus {
            box-shadow: 0 0 0 0.2rem rgb(155 25 46 / 22%);
          }
        }
      }
    }

    .o-form-fieldset:nth-of-type(1),
    .subschema + .o-form-fieldset:nth-child(0) {
      flex: 0 0 100px;
    }

    .o-form-fieldset:nth-of-type(2) {
      flex: 0 0 calc(100% - 120px);
      margin: 0 0 0 10px;
    }
  }
}
.o-form-input {
  position: relative;
}
