@use '../../styles/variables.scss' as *;
.faq-search {
  .dropdown-options__item {
    color: $dimBlack;
    font-family: 'OpenSans-Regular';
    font-size: rem(16px);
    text-align: left;
    min-height: rem(30px);
    padding: rem(5px) rem(16px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &:hover,
    &--selected {
      background-color: $lightSkyBlue;
    }
  }
  .dropdown-input {
    font-size: rem(16px);
    line-height: rem(22px);
    margin-bottom: rem(6px);
  }
  .dropdown-options__header-option {
    font-size: rem(12px);
    line-height: rem(15px);
    margin-bottom: 0px;
  }
  .dropdown-options__content {
    max-height: rem(250px);
    padding: 0px;
    margin-top: rem(10px);
    list-style: none;
    overflow: auto;
  }
  .VCFAQRightDownContentSlotPosition {
    cx-paragraph:first-child {
      div {
        display: none;
      }
    }
    cx-paragraph {
      div {
        font-size: rem(16px);
        margin-bottom: 0;
        padding-right: rem(30px);
      }
    }
  }
  .dropdown__no-result {
    margin-bottom: 0;
  }
  .dropdown__icon {
    z-index: 10;
  }
}
.VCFAQRightDownContentSlotPosition {
  cx-paragraph:first-child {
    div {
      color: $true-blue;
    }
  }
}
.VCFAQRightUPContentSlotPosition {
  cx-paragraph {
    span {
      color: $true-blue;
    }
    div {
      color: $true-blue;
    }
  }
}
