@use 'sass:math';

@function rem($size) {
  @return math.div($size, 16px) + rem;
}

// font family
$openSans-light: 'OpenSans-Light';
$openSans-bold: 'OpenSans-Bold';
$openSans-semiBold: 'OpenSans-SemiBold';
$openSans-regular: 'OpenSans-Regular';
// color variables
$navy-blue: #003593;
$alcon-true-blue: #003595;
$primary-light-beige: #f9f6f1;
$pale-blue: #7294c8;
$light-pale-blue: #7395c9;
$dark-pale-blue: #3964ad;
$white: #ffffff;
$lightSteelBlue: #e5f5ff;
$lightNavyBlue: rgba(0, 53, 147, 0.4);
$darkBlue: #143489;
$dimBlack: #232323;
$deepSkyBlue: #00aeef;
$lightYellowOrange: rgba(222, 202, 163, 0.3);
$lightCyan: #66acb5;
$lightGreen: #66ce8f;
$lightViolet: #d3b9e5;
$darkViolet: #4e0f78;
$red: red;
$lightblue: #007bff;
$lightSkyBlue: #b2e7fa;
$lightGray: #d4d4d4;
$lightCream: #fbf8f3;
$mediumBlack: #1c1c1c;
$lightGrey: #dbdbdb;
$dark-lime-green: #28a745;
$bright-red: #9b192e;
$deep-sky-blue: #00aeef;
$black: #000;
$gainsboro: #e3e3e3;
$greenHaze: #00ae44;
$gray: #ced4da;
$graycyan: #495057;
$romance: #f5efe3;
$orange: #ff6b13;
$lightGray2: #d3d3d3;
$tropicalBlue: #bad1eb;
$poloBlue: #8fadd7;
$vividBlue: #2196f3;
$governerBay: #2ab934;
$mayaBlue: #66cff5;
$coralOrange: #ff8941;
$reefGreen: #daf0a8;
$classicMatteGrey: #6d6d6d;
$disabledGray: #adadad;
$water-blue: #d2e4f6;
$green-cyan: #d1eadb;
$blue-dark: #012e7c;
$systemGray: #818181;

// Breakpoints
$breakpoint-mobile: 768px;
$breakpoint-mobile-max: 767px;
$breakpoint-tablet: 1024px;
$breakpoint-tablet-max: calc(#{$breakpoint-tablet} - 0.02px);

$true-blue: #003595;
$vivid-burgundy: #9b1a2f;
$dark-orange: #ff6b12;
$lime-punch: #c1e66d;
